/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable wrap-rem-on-px/wrap-rem-on-px */
/* stylelint-enable wrap-rem-on-px/wrap-rem-on-px */
/*
USAGE:
font-size: fluid(12, 62); : from rem(12)@375px to  rem(62)@1440px
gap: fluid(10, 33, 992);  : from rem(10)@991px to  rem(33)@1440px
margin-right: fluid(32, 65, 320, 2560);  : from rem(32)@320px to  rem(65)@2560px
*/
/* stylelint-disable */
/* stylelint-enable */
/* Font */
/* Base palette */
/* Brand palette */
/* Theme */
/* State palette */
.qpac.content-card {
  position: relative;
}
.qpac.content-card a {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
}
.qpac.content-card h2 {
  position: absolute;
  z-index: 1;
  top: 0.625rem;
  left: 0.625rem;
  padding: 0.375rem 0.53125rem;
  border-radius: 0.1875rem;
  -webkit-backface-visibility: hidden;
  background-color: #00FFAE;
  color: #1C1D21;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
  -webkit-transform: translateZ(0);
  will-change: transform;
}
@media screen and (min-width:1199px) {
  .qpac.content-card h2 {
    font-size: 0.75rem;
  }
}
.qpac.content-card picture, .qpac.content-card figure {
  overflow: hidden;
  margin-bottom: 0.4375rem;
  aspect-ratio: 1/1;
}
@media screen and (min-width:1199px) {
  .qpac.content-card picture, .qpac.content-card figure {
    margin-bottom: 0.5rem;
  }
}
.qpac.content-card picture img, .qpac.content-card figure img {
  transform: scale(1);
  will-change: transform;
  transition: all 0.3s ease-in-out;
}
.qpac.content-card:hover picture img {
  transform: scale(1.02);
}
.qpac.content-card article {
  position: relative;
  padding-top: 0.0625rem;
  padding-left: 3rem;
}
@media screen and (min-width:1199px) {
  .qpac.content-card article {
    padding-right: 2rem;
  }
}
.qpac.content-card article::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 16.54V0.540039H16V16.54H0ZM1.39891 15.1411H14.6011V1.93895H1.39891V15.1411ZM5.28962 11.2504V5.82966H10.7104V11.2504H5.28962Z' fill='%2316171A'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
}
.qpac.content-card article h3 {
  margin-bottom: 0;
  color: #16171A;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
}
@media screen and (min-width:1199px) {
  .qpac.content-card article h3 {
    font-size: 1rem;
    line-height: 1.1;
  }
}
.qpac.content-card article time {
  color: #4C515C;
  font-size: 0.8125rem;
  line-height: 1.2;
  text-transform: uppercase;
}
.qpac.content-card.content-card--white article::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 16V0H16V16H0ZM1.39891 14.6011H14.6011V1.39891H1.39891V14.6011ZM5.28962 10.7104V5.28962H10.7104V10.7104H5.28962Z' fill='white'/%3E%3C/svg%3E%0A");
}
.qpac.content-card.content-card--white article h3 {
  color: #fff;
}
@media screen and (min-width:1199px) {
  .qpac.content-card.content-card--why-change article h3 {
    font-size: 0.8125rem;
  }
}

.section--serial-tag {
  padding: 6rem 0 clamp(2rem, -3.00752vw + 2.706766875rem, 0rem);
}
.section--serial-tag-grey {
  background-color: #DCDCDC;
}
.section--serial-tag-grey .qpac.serial-tag {
  border-color: #1C1D21;
  color: #1C1D21;
}
.section--serial-tag-grey .qpac.serial-tag .serial-tag__title {
  border-left-color: #1C1D21;
}

.qpac.serial-tag {
  display: inline-flex;
  border: 0.0625rem solid #DCDCDC;
}
.qpac.serial-tag .serial-tag__no {
  padding: 0.4375rem 0.5625rem 0.4375rem 0.4375rem;
  font-size: 0.75rem;
  line-height: 120%;
  text-transform: uppercase;
}
@media screen and (min-width:991px) {
  .qpac.serial-tag .serial-tag__no {
    padding-bottom: 0.375rem;
  }
}
.qpac.serial-tag .serial-tag__title {
  display: flex;
  flex-direction: row;
  padding: 0.4375rem 1.4375rem 0.4375rem 0.4375rem;
  border-left: 0.0625rem solid #DCDCDC;
  gap: 0.5rem;
}
@media screen and (min-width:991px) {
  .qpac.serial-tag .serial-tag__title {
    padding-bottom: 0.375rem;
  }
}
.qpac.serial-tag .serial-tag__title h2 {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}
.qpac.serial-tag .serial-tag__title span {
  font-size: 0.75rem;
  line-height: 120%;
}
.qpac.serial-tag.black-variant {
  border: 0.0625rem solid #1C1D21;
  color: #1C1D21;
}
.qpac.serial-tag.black-variant .serial-tag__title {
  border-left: 0.0625rem solid #1C1D21;
}

section:has(.qpac.content-row) {
  overflow: hidden;
}
@media screen and (min-width:768px) {
  section:has(.qpac.content-row) {
    overflow: unset;
  }
}

section.section.section--content-row {
  background-color: #DCDCDC;
}
section.section.section--content-row.section--content-row--black {
  background-color: #16171A;
}
@media screen and (min-width:1199px) {
  section.section.section--content-row.section--content-row--black .qpac.content-row {
    padding-bottom: 7.25rem;
  }
}
section.section.section--content-row.section--content-row--black .qpac.content-card article time {
  color: #DCDCDC;
}

.qpac.content-row {
  padding: 6rem 0 3.75rem;
}
@media screen and (min-width:1199px) {
  .qpac.content-row {
    padding: 6rem 0 1.25rem;
  }
}
.qpac.content-row .serial-tag {
  margin-bottom: 2.125rem;
}
@media screen and (min-width:1199px) {
  .qpac.content-row .serial-tag {
    margin-bottom: 3.3125rem;
  }
}
.qpac.content-row .content-row__wrapper {
  display: flex;
  margin: 0 -1rem;
}
@media screen and (min-width:991px) {
  .qpac.content-row .content-row__wrapper {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
}
.qpac.content-row .content-row__wrapper .slick-track {
  padding-left: 1rem;
}
.qpac.content-row .content-row__wrapper .slick-slide {
  padding-right: 1rem;
}
.qpac.content-row .content-row__wrapper .slick-list {
  padding-right: 1rem;
}
@media screen and (min-width:991px) {
  .qpac.content-row .content-row__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
.qpac.content-row .content-row__wrapper .content-card {
  margin: 0;
}
@media screen and (min-width:991px) {
  .qpac.content-row .content-row__wrapper .content-card {
    margin: 0 0.5rem;
  }
}
.qpac.content-row .l-title {
  max-width: 47.1875rem;
  padding-left: clamp(0rem, 5.26316vw - 1.236841875rem, 3.5rem);
  margin-bottom: clamp(2.5rem, 1.50376vw + 2.14661625rem, 3.5rem);
  color: #242830;
}
@media screen and (min-width:1199px) {
  .qpac.content-row .serial-tag:has(+ .l-title) {
    margin-bottom: 3.625rem;
  }
}
@media screen and (min-width:1199px) {
  .qpac.content-row--why-change {
    padding: 6rem 0;
  }
}
.qpac.content-row--adaptive-design {
  padding-bottom: clamp(2.5rem, 5.26316vw + 1.263158125rem, 6rem);
}